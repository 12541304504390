
import { Component, Vue } from 'vue-property-decorator'
import UploadFile from '@/components/uploadFile/Index.vue'
import { ElForm } from 'element-ui/types/form'
import { ProductTypeDetail } from '@/types/product'
import { FileInfo } from '@/types/common'

@Component({
  name: 'ProductTypeAdd',
  components: { UploadFile }
})
export default class extends Vue {
  private title = '新增产品类型'
  private info: ProductTypeDetail = {
    productTypeName: '',
    synopsis: '',
    resourceList: []
  }

  private rules = {
    productTypeName: [
      { required: true, message: '请输入产品类型名称', trigger: ['blur', 'change'] }
    ],
    synopsis: [
      { required: true, message: '请输入类型介绍', trigger: ['blur', 'change'] }
    ],
    resourceList: [
      { required: true, message: '请上传图片', trigger: ['blur', 'change'] }
    ]
  }

  private submitShow = false

  get productTypeId () {
    return this.$route.params.id || ''
  }

  created () {
    if (this.productTypeId) {
      this.title = '编辑产品类型'
      this.getDetail()
    }
  }

  // 产品类型详情
  getDetail () {
    this.$axios.get(this.$apis.product.selectProductTypeByProductTypeId, {
      productTypeId: this.productTypeId
    }).then((res) => {
      this.info = res
    })
  }

  onSuccess (file: FileInfo) {
    this.info.resourceList.push(file)
  }

  imgRemove (index: number) {
    this.info.resourceList.splice(index, 1)
  }

  // 提交
  onSubmit () {
    (this.$refs.info as ElForm).validate((valid) => {
      if (valid) {
        this.addProductType()
      }
    })
  }

  // 用户详情
  addProductType () {
    this.submitShow = true
    const url = this.productTypeId
      ? this.$apis.product.updateProductType
      : this.$apis.product.insertProductType
    this.$axios.post(url, this.info).then(() => {
      this.submitShow = false
      this.$message.success('保存成功')
      this.$router.push({ path: '/productList/baseSet/type' })
    }).finally(() => {
      this.submitShow = false
    })
  }
}
